<script setup lang="ts">
import { request, API_URL } from 'aeria-ui'
import { default as Chart } from 'chart.js/auto'

definePage({
  meta: {
    title: 'Estatísticas',
    icon: 'chart-bar'
  }
})

const formData = ref({
  period1: {},
  period2: {},
  products: ''
})

const loading = ref(false)
const annualCollapsed = ref(true)
const annualLoading = ref(false)

const stats = reactive({})
const annual = reactive([])

const comparison = reactive({
  left: {
    count: {
      dispatched: 0,
      reshipment: 0,
      cancelled: 0,
      assembled: 0,
    }
  },
  right: {
    count: {
      dispatched: 0,
      reshipment: 0,
      cancelled: 0,
      assembled: 0,
    }
  }
})

const criteriaPanel = ref(false)
const comparativeLeft = ref(null)
const comparativeRight = ref(null)
let leftChart: any, rightChart: any

const update = async () => {
  loading.value = true
  try {
    const { data } = await request(`${API_URL}/stats/get`, formData.value)
    Object.assign(stats, data)
    updateComparative()

  } finally {
    loading.value = false
    criteriaPanel.value = false
  }
}

const chart = (data: any, title: string) => ({
  type: 'pie',
  data: {
    labels: [
      'Envios',
      'Reenvios',
      'Estornos',
      'Agrupamentos'
    ],
    datasets: [{
      description: 'Dataset 1',
      data: Object.values(data),
      backgroundColor: [
        'orange',
        'purple',
        'darkgreen',
        'indigo'
      ]
    }]
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2/1,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: title
      }
    }
  }
})

const updateCharts = () => {
  leftChart?.destroy()
  rightChart?.destroy()

  leftChart = new Chart(comparativeLeft.value, chart(comparison.left.count, 'Período 1'))
  rightChart = new Chart(comparativeRight.value, chart(comparison.right.count, 'Período 2'))
}

const updateAnnual = async () => {
  annualLoading.value = true

  try {
    const { data } = await request(`${API_URL}/stats/getAnnual`) || {}
    Object.assign(annual, data)
  } finally {
    annualLoading.value = false
  }
}

const updateComparative = async () => {
  const { data } = await request(`${API_URL}/stats/getComparative`, formData.value) || {}
  Object.assign(comparison, data)

  updateCharts()
}

watch(
  () => annualCollapsed.value,
  (value: boolean) => {
    if( !value && annual.length === 0 ) {
      updateAnnual()
    }
  }
)
</script>

<template>
  <div class="
    tw-flex
    tw-flex-col
    tw-gap-6
  ">
    <aeria-button
      class="tw-self-start"
      @click="criteriaPanel = true"
    >
      Critérios
    </aeria-button>

    <aeria-panel
      fill
      transparent-mobile
      collapsible
      outer-header
      title="Relatório anual"
      v-loading="annualLoading"
      v-model:collapsed="annualCollapsed"
    >
      <aeria-table
        v-bind="{
          columns: {
            position: {
              description: 'Mês',
              type: 'text'
            },
            total_sales: {
              description: 'Total de vendas',
              type: 'number'
            },
            total_amount: {
              description: 'Faturamento total',
              type: 'number'
            },
            average_amount: {
              description: 'Faturamento médio por venda',
              type: 'number'
            }
          },
          rows: annual
        }"
      ></aeria-table>
    </aeria-panel>

    <div
      v-loading="loading"
      class="
        tw-flex
        tw-gap-6
        tw-overflow-hidden
    ">
      <div class="
        lg:tw-flex
        lg:tw-h-[25rem]
        tw-flex-1
        tw-justify-around
        tw-gap-2
        tw-rounded-lg
        tw-p-2
        aeria-surface-alt
      ">
        <div>
          <canvas
            ref="comparativeLeft"
            class="tw-max-w-full"
          ></canvas>
        </div>
        <div class="
          tw-flex
          tw-flex-col
          tw-gap-2
          tw-opacity-70
          tw-whitespace-nowrap
          tw-p-6
        ">
          <div>Envios: {{ comparison.left?.count.dispatched }}</div>
          <div>Reenvios: {{ comparison.left?.count.reshipment }}</div>
          <div>Estornos: {{ comparison.left?.count.cancelled }}</div>
          <div>Agrupamentos: {{ comparison.left?.count.assembled }}</div>
        </div>
      </div>
      <div class="
        lg:tw-flex
        lg:tw-h-[25rem]
        tw-flex-1
        tw-justify-around
        tw-gap-2
        tw-rounded-lg
        tw-p-2
        aeria-surface-alt
      ">
        <div>
          <canvas
            ref="comparativeRight"
            class="tw-max-w-full"
          ></canvas>
        </div>
        <div class="
          tw-flex
          tw-flex-col
          tw-gap-2
          tw-opacity-70
          tw-whitespace-nowrap
          tw-p-6
        ">
          <div>Envios: {{ comparison.right?.count.dispatched }}</div>
          <div>Reenvios: {{ comparison.right?.count.reshipment }}</div>
          <div>Estornos: {{ comparison.right?.count.cancelled }}</div>
          <div>Agrupamentos: {{ comparison.right?.count.assembled }}</div>
        </div>
      </div>
    </div>
  </div>

  <aeria-panel
    close-hint
    fixed-right
    title="Critérios"
    v-model="criteriaPanel"
    @overlay-click="criteriaPanel = false"
  >
    <aeria-form
      v-model="formData"
      v-bind="{
        form: {
          period1: {
            description: 'Período',
            type: 'string',
            format: 'date'
          },
          period2: {
            description: 'Período para comparação',
            type: 'string',
            format: 'date'
          },
          products: {
            description: 'Produtos',
            type: 'string'
          }
        },
        searchOnly: true
      }"
    ></aeria-form>

    <template #footer>
      <aeria-button
        large
        @click="update"
      >
        Atualizar
      </aeria-button>
    </template>
  </aeria-panel>

  <aeria-panel
    v-loading="loading"
    fill
    transparent-mobile
    outer-header
    collapsible
    title="Envios por perfil de logística"
  >
    <aeria-table
      v-bind="{
        columns: {
          position: {
            description: '#',
            type: 'number'
          },
          profile_name: {
            description: 'Perfil de logística',
            type: 'text'
          },
          total_labels: {
            description: 'Total de etiquetas',
            type: 'number'
          },
          total_cost: {
            description: 'Custo total',
            type: 'number'
          },
          average_cost: {
            description: 'Custo médio',
            type: 'number'
          },
          percentage: {
            description: '% etiquetas',
            type: 'text'
          }
        },
        rows: stats.shipmentsByProfile
      }"
    ></aeria-table>
  </aeria-panel>

  <aeria-panel
    v-loading="loading"
    fill
    transparent-mobile
    outer-header
    collapsible
    title="Estatística de produto"
  >
    <aeria-table
      v-bind="{
        columns: {
          position: {
            description: '#',
            type: 'number'
          },
          product_name: {
            description: 'Produto',
            type: 'text'
          },
          total_sales: {
            description: 'Vendas',
            type: 'number'
          },
          total_amount: {
            description: 'Crédito total',
            type: 'number'
          },
          average_amount: {
            description: 'Crédito médio',
            type: 'number'
          },
          percentage: {
            description: '% vendas',
            type: 'number'
          }
        },
        rows: stats.products
      }"
    ></aeria-table>
  </aeria-panel>

  <aeria-panel
    v-loading="loading"
    fill
    transparent-mobile
    outer-header
    collapsible
    title="Vendas por região (top 50)"
  >
    <aeria-table
      v-bind="{
        columns: {
          position: {
            description: '#',
            type: 'number'
          },
          _id: {
            description: 'Cidade',
            type: 'text'
          },
          state: {
            description: 'Estado',
            type: 'number'
          },
          most_frequent_product: {
            description: 'Produto mais incidente',
            type: 'text'
          },
          total_sales: {
            description: 'Total de vendas',
            type: 'number'
          },
          percentage: {
            description: '% vendas',
            type: 'number'
          }
        },
        rows: stats.zipcodes
      }"
    ></aeria-table>
  </aeria-panel>
</template>
