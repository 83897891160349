<script setup lang="ts">
import { getGlobalStateManager } from 'aeria-ui';
import { historyPanel } from './store'
import { formatDocument } from '../../../helpers'
import { Order } from '../../../../../api/src/collections/order/description';

definePage({
  meta: {
    title: 'Pedidos',
    icon: 'shopping-cart',
    collection: 'order'
  },
})

const router = useRouter()
const orderStore = useStore('order')
const userStore = useStore('user')

const manager = getGlobalStateManager()

const action = useAction(orderStore, router, manager)
const [call, bus] = action

const errorPanel = ref<any>(false)
const orderHistoryItems = ref<Order[]>([])

const orderHistory = computed<Order[]>(() => {
  if( !orderHistoryQuery.value ) {
    return orderHistoryItems.value
  }
  
  return orderHistoryItems.filter((order: Order) => {
    const exp = new RegExp(orderHistoryQuery.value, 'i')
    return exp.test(order.customer.name)
      || exp.test(order.receiver_name as string)
      || exp.test(order.token as string)
      || exp.test(order.products.map(p => p.name).join(""))
  })
})
const orderHistoryQuery = ref('')

const openHistoryPanel = async () => {
  await userStore.$actions.authenticate({ revalidate: true })
  const orderIds: string[] = userStore.currentUser.order_history?.filter((order: Order) => typeof order === 'string')

  console.log({
  c: userStore.currentUser,
  orderIds,
  })

  if( orderIds?.length > 0 ) {
    const { result: { data: orders } }: { result: { data: Order[] }} = await orderStore.$functions.getAll({
      filters: {
        _id: {
          $in: orderIds
        }
      },
      limit: 100,
    })

    orderHistoryItems.value.splice(0)
    orderHistoryItems.value.push(...orderIds.map(id => orders.find(order => order._id === id)).filter(order => !!order))
  }

  historyPanel.value = true
}
</script>

<template>
  <teleport
    v-if="errorPanel"
    to="main"
  >
    <aeria-panel
      v-model="errorPanel"
      float
      close-hint
      title="Pedido com erro"
      @overlay-click="errorPanel = false"
    >
      <div v-if="errorPanel.shipping_profile">
        Esse pedido apresentou erro ao ser enviado pela transportadora <b>{{ errorPanel.shipping_profile.provider }}</b> no perfil <b>{{ errorPanel.shipping_profile.name }}</b>.
        <div class="
          tw-text-red-600
          tw-my-4
        ">
          {{ errorPanel.error }}
        </div>
      </div>

      <template #footer>
        <aeria-button
          large
          icon="pencil"
          @click="call({ action: 'spawnEdit' })(errorPanel); errorPanel = false
        ">
          Editar pedido
        </aeria-button>
      </template>
    </aeria-panel>
  </teleport>

  <failed-orders-warning
    v-if="router.currentRoute.value.query.section !== 'failed'"
  ></failed-orders-warning>

  <aeria-crud
    collection="order"
    :action="action"
  >
    <template #actions>

      <aeria-button
        icon="calendar"
        @click="openHistoryPanel"
      >
      
        Histórico
      <aeria-badge>
          Novo!
      </aeria-badge>
      </aeria-button>
    </template>

    <template #row-local_id="{ row, column }">
      <div class="
        tw-flex
        tw-flex-col
        tw-gap-1
      ">
        <div>{{ row[column] }}</div>
        <div class="
          tw-text-[8.5pt]
          tw-opacity-60
        ">
          {{ row.token }}
        </div>
      </div>
    </template>

    <template #row-customer="{ row, column }">
      <div class="
        tw-flex
        tw-flex-col
        tw-gap-1
      ">
        <div>{{ row[column].name }}</div>
        <div class="
          tw-text-[8.5pt]
          tw-opacity-60
        ">
          {{ formatDocument(row[column].document) }}
        </div>
      </div>
    </template>

    <template #row-status="{ row, column }">
      <status-badge :color="orderStore.$actions.getComputedColor(row[column])">
        {{ t(row[column]||'-') }}
      </status-badge>
    </template>

    <template #row-products="{ row, column }">
      <div v-if="row.content_description">
        {{ row.content_description }}
      </div>

      <span
        v-else
        class="
          tw-flex
          tw-items-center
          tw-gap-1
      ">
        <extra-icon :name="row.integration?.platform"></extra-icon>
        <div>
          {{ row[column].map(product => product.name).join(', ') }}
        </div>
      </span>

    </template>

    <template #row-tracking_code="{ row, column }">
      <div
        v-if="row[column]"
        class="
          tw-flex
          tw-items-center
          tw-gap-1
      ">
        <extra-icon
          v-if="row.shipping_profile"
          :name="row.shipping_profile.provider"
        ></extra-icon>
        <div>{{ row[column] }}</div>
      </div>

      <div
        v-else-if="row.error"
        class="
          tw-flex
          tw-items-center
          tw-gap-1
      ">
        <extra-icon
          v-if="row.shipping_profile"
          :name="row.shipping_profile.provider"
        ></extra-icon>

        <aeria-button
          small
          variant="transparent"
          icon="exclamation-triangle"
          @click="errorPanel = row"
        >
          Erro!
        </aeria-button>
      </div>

      <div v-else>
        -
      </div>
    </template>

    <template #row-error="{ row, column }">
      <div class="
        tw-flex
        tw-gap-2
      ">
        <extra-icon
          v-if="row.shipping_profile?.provider"
          :name="row.shipping_profile.provider"
        ></extra-icon>
        <div>{{ row[column] }}</div>
      </div>
    </template>
  </aeria-crud>

  <aeria-panel
    close-hint
    float
    title="Histórico de pedidos abertos"
    v-model="historyPanel"
    @overlay-click="historyPanel = false"
  >
    <aeria-input
      class="tw-w-full"
      v-model="orderHistoryQuery"
      :property="{
        icon: 'magnifying-glass',
        placeholder: 'Token, nome do cliente ou nome do produto'
      }"
    ></aeria-input>

    <div class="
      tw-flex
      tw-flex-col
      tw-gap-3
      tw-overflow-scroll
      lg:tw-w-[40rem]
    ">
      <div
        v-clickable
        v-for="order in orderHistory"
        :key="order._id"
        class="
          tw-transition-all
          tw-flex
          tw-flex-col
          tw-text-[11pt]
          tw-gap-3
          tw-p-4
          tw-border
          tw-rounded-lg
          hover:tw-shadow-lg
        "
        @click="historyPanel = false; $router.push({
          name: '/dashboard/order/view/[id]',
          params: {
            id: order._id
          }
        })"
      >
        <div class="
          tw-flex
          tw-items-center
          tw-justify-between
          tw-gap-4
        ">
          <div>
            <div class="
              tw-font-[500]
            ">
              {{ order.local_id }}
            </div>
            <div class="
              tw-opacity-60
              tw-text-[9pt]
            ">
              {{ order.token }}
            </div>
          </div>

          <status-badge :color="orderStore.$actions.getComputedColor(order.status)">
            {{ t(order.status||'-') }}
          </status-badge>
        </div>

        <div>
          <div>{{ order.customer.name }}</div>
          <div>Criado em: {{ formatDateTime(order.date_created, true) }}</div>
        </div>


        <div class="tw-text-[10pt]">
          <div class="
            tw-flex
            tw-items-center
            tw-gap-1
          ">
            <extra-icon
              size="1rem"
              :name="order.integration?.platform"
            ></extra-icon>
            <div>
              {{ order.products.map(product => product.name).join(', ') }}
            </div>
          </div>

          <div
            v-if="order.tracking_code"
            class="
              tw-flex
              tw-items-center
              tw-gap-1
              tw-text-[10pt]
          ">
            <extra-icon
              size="1rem"
              :name="order.shipping_profile?.provider"
            ></extra-icon>
            <div>{{ order.tracking_code }}</div>
          </div>
        </div>

      </div>
    </div>
  </aeria-panel>

</template>
