<script setup lang="ts">
import { DashboardLayout } from 'aeria-app-layout'

const userStore = useStore('user')
const metaStore = useStore('meta')
const organizationStore = useStore('organization')

const organizations = ref([] as Array<any>)

onMounted(async () => {
  organizations.value = (
    await organizationStore.$functions.getMyOrganizations({})
  ).data
})

async function changeOrganization() {
  await userStore.$functions.insert({
    what: {
      _id: userStore.currentUser._id,
      current_organization: userStore.currentUser.current_organization,
    },
  })

  await userStore.$actions.authenticate({ revalidate: true })
  ROUTER.go(0)
}
</script>

<template>
  <dashboard-layout class="dashboard">
    <template #super>
      <aeria-icon
        v-clickable
        icon="headphones"
        @click="$router.push('/dashboard/support')"
      >
        Suporte
      </aeria-icon>

      <div class="org">
        <aeria-select
          v-model="userStore.currentUser.current_organization"
          :property="{
            enum: [],
            icon: 'buildings',
          }"
          @change="changeOrganization"
        >
          <option v-for="org in organizations" :value="org._id">
            {{ org.name }}
          </option>
        </aeria-select>
      </div>

      <!-- <aeria-icon icon="moon"></aeria-icon> -->
      <!-- <aeria-icon icon="sun"></aeria-icon> -->
      <aeria-icon
        v-clickable
        :icon="
          metaStore.theme === 'dark'
            ? 'moon'
            : 'sun'
        "
        @click="metaStore.theme === 'dark'
          ? metaStore.$actions.saveTheme('default')
          : metaStore.$actions.saveTheme('dark')
        "
        >
      </aeria-icon>
    </template>
  </dashboard-layout>
</template>

<style scoped lang="less">
.org {
  font-size: 15px;
  font-family: 'Inter', sans-serif;

  :deep(*) {
    background: var(--theme-brand-color-shade-2) !important;
    color: var(--theme-brand-color-contrast);
  }
}
</style>
