business: empresa | empresas
customer: cliente | clientes
failedOrder: pedido problemático | pedidos problemáticos
geolocation: endereço | endereços
integration: integração | integrações
lateOrder: pedido em atraso | pedidos em atraso
order: pedido | pedidos
orderReview: revisão de pedido | revisões de pedido
platform: plataforma | plataformas
product: produto | produtos
productPlan: plano | planos
reshipment: reenvio | reenvios
shipment: remessa | remessas
shippingProfile: perfil de logística | perfis de logística
shippingProvider: transportadora | transportadoras
shippingService: serviço de entrega | serviços de entrega
shippingTable: tabela de frete | tabelas de frete

created: criado
authorized: autorizado
waiting_payment: pendente
paid: pago
handling_products: em preparação
on_carriage: a caminho
delivered: entregue
cancelled: cancelado
refused: recusado
invoiced: nota fiscal emitida
shipment_exception: exceção na entrega
in_review: em revisão
sent: enviado
pending: pendente

settled: resolvido
unsettled: não resolvido
shipping_cancel: Cancelamento de envio
address_change: Alteração de envio
quantity_change: Alteração de quantidade
product_change: Alteração de produto
modality_change: Alteração de modalidade
not_rejected: Não foi rejeitado
already_processed: Já havia sido aferido pelo sistema
already_posted: Já havia sido postado
already_shipping: Passou por troca e já está em transporte

none: Nenhum
problematic: problemático
healthy: saudável

name: nome
document: documento

content_description: Conteúdo
height: Altura
width: Largura
weight: Peso
declared_value: Valor declarado
amount: Valor

receiver_name: Nome do destinatário
receiver_address: Endereço do destinatário
receiver_district: Bairro do destinatário
receiver_city: Cidade do destinatário
receiver_document: CPF do destinatário
receiver_number: Número do endereço destino
receiver_complement: Complemento do destinatário
receiver_state: Estado do destinatário (UF)
receiver_email: Email de contato
receiver_phone: Telefone

address: Endereço
district: Bairro
city: Cidade
number: Número
complement: Complemento
state: Estado

debit: débito
credit: crédito

date_min: Data mínima
date_max: Data máxima
orderpaid: Pago
orderupdated: Atualizado
ordertoken: Token
mapping: Mapeamento
